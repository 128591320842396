import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import RadioDescription from '../components/RadioDescription';
import RadioMarketing from '../components/RadioMarketing';
import RadioGuarantee from '../components/RadioGuarantee';

import ModelsHeaderDesktopImage from '../images/models-header-desktop.jpg';
import ModelsHeaderMobileImage from '../images/models-header-mobile.jpg';
import MototrboR7DesktopImage from '../images/mototrbo-r7-desktop.png';
import MototrboR2DesktopImage from '../images/mototrbo-r2-desktop.png';

function ModelsPage() {
  return (
    <div>
      <Header
        mobileImage={ModelsHeaderMobileImage}
        desktopImage={ModelsHeaderDesktopImage}
        showSelector
      />
      <RadioDescription
        title='MOTOTRBO R7'
        subtitle='¡Siente el poder de ser escuchado!'
        buttonText='Obtén tu radio MOTOTRBO R7 aquí'
        mainImg={MototrboR7DesktopImage}
        isBlueButton
      />
      <RadioDescription
        title='MOTOTRBO R2'
        subtitle='Su mejor aliado'
        buttonText='Obtén tu radio MOTOTRBO R2 aquí'
        mainImg={MototrboR2DesktopImage}
        isGray
      />
      <RadioGuarantee />
      <RadioMarketing />
      <Footer />
    </div>
  );
}

export default ModelsPage;
